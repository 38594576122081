<template>
	<div>
        <LoadingScreen v-if="loading_screen" />
		<contentHeader title="Pemesanan Obat" url="/pemesanan-obat" subTitle="Pemesanan Obat" />

		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row d-flex align-items-center">
                                <h3 class="card-title col-6">Tambah Pemesanan Obat</h3>
                            </div>
                            <form @submit.prevent="prosesPembelian" v-if="pembelian">
                                <div class="card-body">
                                    <div class="px-lg-2">
                                        <div class="row">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="">No. Faktur <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="pembelian.nomor_faktur" required="">
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="">Supplier <span class="text-danger">*</span></label>
                                                    <el-select v-model="cabang_selected" placeholder="Pilih Supplier" class="w-100">
                                                        <el-option v-for="(row, index) in cabang" :key="index" :value="row.id" :label="row.nama"></el-option>
                                                    </el-select>
                                                </div>
                                            </div>
                                        </div>

                                        <table class="table table-bordered table-hover mt-2">
                                            <thead>
                                                <tr>
                                                    <th scope="col col-lg-3" class="text-sm">NAMA PRODUK</th>
													<th scope="col" class="text-sm">EXPIRED DATE</th>
													<th scope="col" class="text-sm">ISI PERBOX</th>
													<th scope="col" class="text-sm">JUMLAH BOX</th>
													<th scope="col" class="text-sm">QTY</th>
                                                    <th scope="col" class="text-center"><i @click="tambahPembelian" class="fas fa-plus-circle fa-2x text-secondary" style="cursor: pointer;"></i></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(row, index) in produk_dibeli" :key="index">
                                                    <td>
                                                        <select class="form-control" placeholder="Pilih Produk" :loading="loading_produk" v-model="row.produk_id" @change="pilihProduk(index)">
                                                            <option v-for="(product, indexProduct) in produk" :key="indexProduct" :value="product.id">{{ product.nama }}</option>
                                                        </select>
                                                    </td>
													<td>
														<input type="date" disabled="" name="" class="form-control" min="0" v-model="row.expired_date">
													</td>
													<td>
														<input type="number" disabled="" name="" class="form-control" min="0" v-model="row.isi_perbox">
													</td>
                                                    <td>
                                                        <input type="number" name="" class="form-control" min="1" v-model="row.jumlah" @keyup="hitungTotalPembelian" @change="hitungTotalPembelian">
                                                    </td>
													<td>
														<input type="number" disabled="" name="" class="form-control" min="1" v-model="row.qty">
													</td>
                                                    <td><div class="btn btn-danger btn-sm text-white text-sm" @click="hapusProdukDipilih(index)">HAPUS</div></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div class="row mt-2">
                                            <div class="col">
                                                <label class="text-sm">Diskon</label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <div class="btn btn-secondary">{{ pembelian.label_diskon }}</div>
                                                    </div>
                                                    <!-- /btn-group -->
                                                    <input type="number" class="form-control" step="any" v-model="pembelian.diskon" @keyup="hitungTotalPembelian">
                                                </div>
                                            </div>
                                            <div class="col">
                                                <label class="text-sm">PPN</label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <div class="btn btn-secondary">{{ pembelian.label_ppn }}</div>
                                                    </div>
                                                    <!-- /btn-group -->
                                                    <input type="number" class="form-control" step="any" v-model="pembelian.ppn" @keyup="hitungTotalPembelian">
                                                </div>
                                            </div>
                                            <div class="col">
                                                <label class="text-sm">Ongkos Kirim</label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <div class="btn btn-secondary">Rp. </div>
                                                    </div>
                                                    <!-- /btn-group -->
                                                    <input type="number" class="form-control" step="any" v-model="pembelian.ongkos_kirim" @keyup="hitungTotalPembelian">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col col-lg-3">
                                                <label class="text-sm">Total</label>
                                                <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <div class="btn btn-secondary">Rp. </div>
                                            </div>
                                            <!-- /btn-group -->
                                            <input type="number" class="form-control" step="any" v-model="pembelian.total" disabled="">
                                            </div>
                                            </div>
                                        </div>

                                        <button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
                                            <div>Submit</div>	
                                            <div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader2'
	import LoadingScreen from '@/components/LoadingScreen'

	import { onMounted, ref, reactive, watch, computed } from 'vue'
	import axios from 'axios'
    import { useRoute, useRouter } from 'vue-router'
	import Swal from 'sweetalert2'
    import store from '@/store'

    const route = useRoute()
    const router = useRouter()

    // Cabang
	const cabangLogin = computed(() => store.getters['auth/cabang'])
    const loading_cabang = ref(false)
    const cabang = ref(null)
    const cabang_selected = ref(null)
    const getCabang = async () => {
        let { data } = await axios.get(`api/cabang/${cabangLogin.value.tipe}`)
        
        if (data == 'kosong') {
            loading_cabang.value = false
            cabang.value = null
        } else {
            loading_cabang.value = false
            cabang.value = data
        }
    }
    // End Cabang

    // Produk
    const produk = ref([])
    const loading_produk = ref(false)
    const getProduk = async (id) => {
		produk.value = []
		loading_produk.value = true
		let { data } = await axios.get(`api/stok_produk/getStokProduk/${id}`)
		
		if (data == 'kosong') {
			loading_produk.value = false
			produk.value = []
		} else {
			loading_produk.value = false
			produk.value = data
		}
	}

    watch(() => cabang_selected.value, (val) => getProduk(val))
    // End Produk

    // Pembelian
    const loading_screen = ref(false)
    const pembelian = reactive({
		"pembeli_id" : "",
		"nama_pembeli" : "",
		"penjual_id" : "",
		"nama_penjual" : "",
		"nomor_faktur" : "",
		"produk" : [],
		"sub_total" : "",
		"label_diskon" : "...",
		"diskon" : "",
		"label_ppn" : "...",
		"ppn" : "",
		"ongkos_kirim" : "",
		"total" : ""
	})
    const produk_dibeli = ref([])

    const getPemesanan = async () => {
        loading_screen.value = true
        await axios.get(`api/pembelian/getPemesananDetail/${cabangLogin.value.tipe}/${route.params.id}`)
        .then((res) => {
            // console.log(res)
            cabang_selected.value = res.data.penjual_id

            pembelian.pembeli_id = res.data.pembeli_id
            pembelian.nama_pembeli = res.data.nama_pembeli
            pembelian.penjual_id = res.data.penjual_id
            pembelian.nama_penjual = res.data.nama_penjual
            pembelian.nomor_faktur = res.data.nomor_faktur
            pembelian.produk = []
            pembelian.sub_total = res.data.sub_total
            pembelian.label_diskon = 'Rp.'
            pembelian.diskon = res.data.diskon
            pembelian.label_ppn = 'Rp.'
            pembelian.ppn = res.data.ppn
            pembelian.ongkos_kirim = res.data.ongkos_kirim
            pembelian.total = res.data.total

            res.data.pembelian_detail.map((val) => {
                produk_dibeli.value.push({
                    "produk_id" : val.produk_id,
                    "nama_produk" : val.produk.nama,
                    "harga_beli" : val.harga_beli,
                    "expired_date" : val.expired_date,
                    "isi_perbox" : val.produk.jumlah_satuan,
                    "jumlah" : val.jumlah,
                    "qty" : val.jumlah * val.produk.jumlah_satuan,
                    "label_diskon" : val.diskon <= 100 ? 'Persen (%) ' : 'Rp. ',
                    "diskon" : val.diskon,
                    "total" : val.total
                })
            })
        })
        .finally(() => {
            getCabang()
            loading_screen.value = false
        })
    }
    // End Pembelian

    // Tambah Pembelian
    const tambahPembelian = () => {
        produk_dibeli.value.push({
            "produk_id" : null,
            "nama_produk" : null,
            "harga_beli" : null,
            "expired_date" : null,
            "jumlah" : 1,
            "label_diskon" : 'Rp. ',
            "diskon" : null,
            "total" : null
        })
    }
    // End Tambah Pembelian

    // Hapus Pembelian
    const hapusProdukDipilih = (index) => {
        produk_dibeli.value.splice(index, 1)
        hitungTotalPembelian()
    }
    // End Hapus Pembelian

    // Pilih Produk
    const pilihProduk = (id) => {
        for (var i = 0; i < produk.value.length; i++) {
			if (produk.value[i].produk_id == produk_dibeli.value[id].produk_id) {
				produk_dibeli.value[id].nama_produk = produk.value[i].nama
				produk_dibeli.value[id].harga_beli = produk.value[i].produk.harga_jual
				produk_dibeli.value[id].expired_date = produk.value[i].expired_date
				produk_dibeli.value[id].isi_perbox = produk.value[i].produk.jumlah_satuan
				produk_dibeli.value[id].jumlah = produk.value[i].produk.jumlah_satuan_besar
				produk_dibeli.value[id].qty = produk.value[i].produk.jumlah_satuan * produk.value[i].produk.jumlah_satuan_besar
				produk_dibeli.value[id].total = parseInt(produk.value[i].produk.jumlah_satuan) * parseInt(produk.value[i].produk.harga_jual)
			}
		}
        hitungTotalPembelian()
    }
    // End Pilih Produk

    // Hitung Total Pembelian
    const hitungTotalPembelian = () => {
        pembelian.sub_total = 0
        produk_dibeli.value.map((val) => {
            val.total = val.harga_beli * val.jumlah
		    pembelian.sub_total = pembelian.sub_total + (val.harga_beli * val.jumlah)
        })

		pembelian.total = pembelian.sub_total

		if (pembelian.diskon != '') {
			if (pembelian.diskon <= 100) {
				pembelian.label_diskon = 'Persen (%)'
				let diskon = (pembelian.sub_total * pembelian.diskon) / 100
				pembelian.total = pembelian.total - diskon
			} else {
				pembelian.label_diskon = 'Rp. '
				let diskon = pembelian.diskon
				pembelian.total = pembelian.total - diskon
			}
		} else {
			pembelian.label_diskon = '...'
		}

		if (pembelian.ppn != '') {
			if (pembelian.ppn <= 100) {
				pembelian.label_ppn = 'Persen (%)'
				let ppn = (pembelian.sub_total * pembelian.ppn) / 100
				pembelian.total = pembelian.total + ppn
			} else {
				pembelian.label_ppn = 'Rp. '
				let ppn = pembelian.ppn
				pembelian.total = pembelian.total + ppn
			}
		} else {
			pembelian.label_ppn = '...'
		}

		if (pembelian.ongkos_kirim != '') {
			pembelian.total = pembelian.total + pembelian.ongkos_kirim
		}
	}
    // End Hitung Total Pembelian

    // Proses Pembelian
    const loading = ref(false)
    const prosesPembelian = () => {
		loading.value = true
		pembelian.produk = produk_dibeli.value.filter((val) => val.produk_id != null)
		
		axios.put(`api/pembelian/update/${route.params.id}`, pembelian)
		.then(() => {
			Swal.fire({
				title: 'Berhasil!',
				text: 'Berhasil mengubah Data',
				icon: 'success',
				showConfirmButton: false,
				timer: 1500
			})
			loading.value = false
			router.push('/pemesanan-obat')
		})
		.catch(() => {
			Swal.fire({
				title: 'Gagal!',
				text: 'Gagal mengubah data',
				icon: 'error',
				showConfirmButton: false,
				timer: 1500
			})	
			loading.value = false
		})
	}
    // End Proses Pembelian


    onMounted(() => {
        getPemesanan()
    })
</script>